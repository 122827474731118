.acc {
    &__container {
        padding: 8px 20px;
    }

    &__userBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__iconBox {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__userBox {
        margin-bottom: 25px;

        h2 {
            font-weight: 500;
            font-size: 18px;
            color: #334D6E;
        }

        p {
            font-size: 15px;
            color: #868686;
        }
    }

    &__avatar {
        padding: 20px;
        border-radius: 50%;
        background-color: #C4C4C4;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #000;
    }

    &__item {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        margin: 5px 0;

        img {
            margin: 0 10px 0 2px;
            width: 20px;
            height: 20px;
        }

        p {
            font-weight: 500;
            font-size: 16px;
            color: #334D6E;
        }
    }

    &__footer {
        margin-top: 20px;
        font-weight: 500;
        font-size: 12px;
        color: #868686;
    }

    &___li-authorization {
        display: inline;
    }

    &__menuIcon {
        width: 30px !important;
        height: 30px !important;
    }


    &__menuItemIcon {
        width: 24px;
        height: 24px;
    }

    @media screen and (max-width: 900px) {
        &__menuIcon {
            width: 16px !important;
            height: 16px !important;
        }
        &__userBox {
            h2 {
                font-size: 12px;
            }

            p {
                font-size: 10px;
            }
        }

        &__item p {
            font-size: 10px;
        }

        &__menuItemIcon {
            width: 18px !important;
            height: 18px !important;
        }

        &__avatar {
            height: 28px !important;
            width: 28px !important;
            padding:  15px !important;
        }

        &__userBox {
            margin-bottom: 10px;
        }
    }
}