.container {
  min-height: 100vh;
  display: flex;
}

.leftContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 6rem;
}

.leftHeading {
  font-weight: 700;
  font-size: 40px;
  line-height: 63px;
  color: #008095;
  margin-bottom: 20px;
}

.signinContainer {
  background-image: url("../../assets/img/signinbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }
}

.signinRightText h1 {
  color: #fff;
  font-weight: 700;
  font-size: 40px;
  text-align: center;

  >span {
    font-size: 32px;
    color: #334d6e;
    background-color: #ffffff;
    padding: 10px;
    width: fit-content;
    margin-bottom: 20px;
  }
}

.efficientHiring {
  background-color: #fff;
  color: rgb(0, 128, 149);
  padding: 8px 15px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 25px;
}

.inputBox {
  margin-bottom: 20px;
}

.inputBox label {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  display: block;
}

.inputBox input {
  background: #F1F1F1;
  border-radius: 30px;
  padding: 15px;
  width: 100%;
  font-size: 16px;
}

.inputBox input::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
}

.btnBox {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}

.loginBtn {
  margin-left: 10px;
  text-align: left;

  p {
    color: rgba(94, 94, 94, 0.8);
    font-size: 12px;
  }

  a {
    font-size: 18px;
    color: #047C93;
    font-weight: 600;
    text-decoration: none;
  }
}

.btnBox .signupBtn {
  background: linear-gradient(99.98deg, #215D7F 18.73%, #047C93 81.12%);
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  padding: 8px 40px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.external {
  display: flex;
  align-items: center;
}

.logo {
  width: 150px;
  margin-bottom: 12px;
}

.loginButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  color: #FFFFFF;
  background: linear-gradient(99.98deg, #215D7F 18.73%, #047C93 81.12%);
  border-radius: 30px;
  padding: 0px 30px;
  border: none;
  width: fit-content;
}

.error {
  color: red;
  opacity: 0.5;
  font-size: 12px;
  margin-bottom: 20px;
}

.signinRightText-mobile {
  display: none;
}

.title {
  font-weight: 800;
  font-size: 22px;
  line-height: 38px;
  color: #ffffff;
  margin-bottom: 8px;
}

.content {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  &Wrapper {
    padding-inline-start: 20px;
  }
}

.heading {
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
  margin: 20px 0;
}

.styledHeading {
  font-size: 24px;
  color: #334d6e;
  background-color: #ffffff;
  padding: 10px;
  width: fit-content;
  margin-bottom: 20px;
}

.video {
  height: 100%;
  object-fit: scale-down;
  width: 100%;
  border-radius: 0.7rem;
  max-height: 100%;
  border: 0;
}

.orText {
  margin: 0 0.5rem;
  font-weight: 500;
  color: rgba(94, 94, 94, 0.8);
}

.googleSignupBtn {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084),
    0px 1px 1px rgba(0, 0, 0, 0.168);
  border-radius: 2px;
  cursor: pointer;
}

.googleLogo {
  width: 24px;
  height: 24px;
}

.googleLogoText {
  margin-left: 15px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  line-height: 23px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif !important;
}

@media only screen and (max-width: 700px) {
  .singinBg {
    display: none;
  }

  .leftContainer {
    width: 50%;
    background-image: url("../../assets/img/signinbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    padding: 50px 30px;
    justify-content: center;
  }

  .btnBox {
    flex-direction: column;
    margin-bottom: 0;
  }

  .btnBox .signupBtn {
    border: 1px solid #ffffff;
    padding: 2px 32px;
    font-size: 18px;
  }

  .inputBox {
    margin-bottom: 14px;

    label {
      font-size: 16px;
      padding-bottom: 0.25rem;
    }

    input {
      padding: 5px 11px;
      font-size: 14px;

      &::placeholder {
        font-size: 12px;
      }
    }
  }

  .orText {
    color: #fff;
    font-size: 14px;
    margin: 0.25rem 0;
  }

  .googleSignupBtn {
    padding: 6px 15px;
  }

  .googleLogo {
    width: 20px;
    height: 20px;
  }

  .googleLogoText {
    font-size: 14px;
  }

  .inputBox label,
  .leftHeading,
  .loginBtn p,
  .loginBtn a {
    color: #ffffff;
    margin-left: 0;
  }

  .leftHeading {
    display: none;
  }

  .signinRightText-mobile {
    display: block;
    color: white;
    margin-bottom: 14px;

    h1 {
      font-size: 18px;
    }
  }

  .efficientHiring {
    font-size: 20px;
    padding: 2px 10px;
  }

  .logo {
    width: 200px;
    margin-bottom: 35px;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }

  .styledHeading {
    font-size: 20px;
    margin-bottom: 0.5rem;
  }

  .loginBtn {
    margin-left: 0;
    margin-top: 1rem;
  }
}