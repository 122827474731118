.btn {
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    padding: 5px 20px;
    border-radius: 17px;
    color: #fff;
    margin: 0 10px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    height: fit-content;

    &:disabled {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: not-allowed;
    }

    &-primary {
        background-color: #479bd2;

        &-outlined {
            font-size: 14px;
            padding: 5px 16px;
            color: #479bd2;
            background-color: #fff;
            border: 1px solid #479bd2;
        }
    }

    &-secondary {
        background-color: #9fa0a0;
    }

    &-success {
        background-color: #4fca64;
    }

    @media screen and (max-width: 900px) {
        font-size: 10px;
    }
}

.startIcon {
    display: inline-flex;
    margin-right: 16px;
}

.endIcon {
    display: inline-flex;
    margin-left: 16px;
}


