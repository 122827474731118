.container {
    display: flex;
    min-height: 100vh;
}

.instructionContainer {
    background-image: url("../../assets/img/signinbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.instructions {
    padding: 8px;
}

.title {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    color: #ffffff;
    margin-bottom: 8px;
}

.content {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;

    &Wrapper {
        padding-inline-start: 20px;
    }
}

.heading {
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    margin: 20px 0;
}

.styledHeading {
    font-size: 20px;
    line-height: 44px;
    color: #334d6e;
    background-color: #ffffff;
    padding: 10px;
    width: fit-content;
    margin-bottom: 20px;
}

.signupContainer {
    width: 50%;
    padding: 20px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.signupHeading {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 44px;
    display: flex;
    align-items: center;
    color: #135871;
    margin-bottom: 50px;
}

.input {
    display: block;
    margin: 24px 0px;
    border-bottom: 1px solid rgba(26, 23, 23, 0.38);
    width: 100%;
}

.orText {
    opacity: 0.4;
    display: block;
    margin: 10px 0;
}

.textCenter {
    text-align: center;
}

.signupBtn {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084),
        0px 1px 1px rgba(0, 0, 0, 0.168);
    border-radius: 2px;
    cursor: pointer;
}

.googleLogo {
    width: 24px;
    height: 24px;
}

.googleLogoText {
    margin-left: 15px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 500;
    line-height: 23px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif !important;
}

.modalHeading {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 30px;
    color: #008095;
    margin-top: 50px;
}

.modalContent {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: #404040;
    margin-top: 30px;
}

.mt50 {
    margin-top: 70px;
}

.linkedinLogo {
    display: inline-block;
    width: 130px;
    margin: 30px;
    cursor: pointer;
}

.linkedinRecLogo {
    display: inline-block;
    width: 140px;
    margin: 30px;
    cursor: pointer;
}

.linkedInText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 48px;
    color: #006699;
}

.logoContainer {
    display: flex;
    justify-content: center;
}

.linkedinContainer {
    margin-right: 80px;
}

.loginText {
    margin-top: 2rem;
    font-size: 16px;

    a {
        font-weight: 600;
        color: #008095;
        text-decoration: underline;
    }
}

.video {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
    border-radius: 0.7rem;
    max-height: 100%;
    border: 0;
}