.container {
    background-color: #ffffff;
    height: 100%;
    margin-left: -20px;
    margin-top: -10px;
    border: 1px solid #cccccc8F;
    padding: 10px;
    height: 100vh;
    overflow: auto;
}

.jobFunctionButton {
    margin-top: 10px !important;
    margin-right: 10px !important;
    box-shadow: none !important;
    color: #008095 !important;
    border: 1px solid #008095 !important;
}

.jobFunctionSelected {
   background-color: #008095 !important;
   color: #ffffff !important;

}

.fieldContainer {
    margin-bottom: 15px;
}

.fieldTitle {
    margin-bottom: 5px !important;
}

.error {
    color: red !important;
    opacity: 0.8;
    margin-top: 10px;
}