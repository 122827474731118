.sp {
    &__actionbar {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #fff;
        border-radius: 12px;
        padding: 14px;
        margin-top: 16px;
    }

    &__actionItem {
        color: #334D6E;
        font-size: 16px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;

        &-filter {
            cursor: pointer;
        }

        span {
            color: #90A0B7;
            font-weight: 400;
        }

        &-img {
            margin-left: 6px;
            width: 10px;
            height: 10px;
        }
    }

    @media screen and (max-width: 900px) {
        &__heading {
          display: flex;
          align-items: center;

          >p {
            font-size: 10px;
          }
        }

        &__actionItem {
          font-size: 10px !important;
        }
    }
}