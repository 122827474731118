.container {
  display: flex;
  min-height: 100vh;
}

.leftContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 100px;
}

.leftHeading {
  font-weight: 700;
  font-size: 40px;
  line-height: 63px;
  color: #008095;
  margin-bottom: 50px;
}

.signinContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
}

.signinRightText {
  color: #fff;

  h1 {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 20px;

    span {
      font-weight: 500;
      font-size: 36px;
      color: #334d6e;
      background-color: #ffffff;
      padding: 10px;
    }
  }
}

.singinBg {
  width: 50%;
  background-image: url("../../assets/img/signinbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.efficientHiring {
  background-color: #fff;
  color: rgb(0, 128, 149);
  padding: 8px 15px;
  box-sizing: border-box;
  font-weight: 600;
  font-size: 25px;
}

.inputBox {
  margin-bottom: 20px;
}

.inputBox label {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  display: block;
}

.inputBox input {
  background: #f1f1f1;
  border-radius: 30px;
  padding: 15px;
  width: 100%;
  font-size: 16px;
}

.inputBox input::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
}

.inputEmail {
  margin: 2rem 0 3rem;
}

.btnBox {
  margin-bottom: 20px;
}

.btnBox button {
  background: linear-gradient(99.98deg, #215d7f 18.73%, #047c93 81.12%);
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff;
  padding: 8px 40px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.btnBox a {
  text-decoration-line: underline;
  color: #225e80;
  margin-left: 20px;
}

.btnBox span {
  text-decoration-line: underline;
  color: #225E80;
  margin-left: 20px;
  cursor: pointer;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
}

.external {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  width: fit-content;
  cursor: pointer;

  &:hover {
    border-color: rgb(211 211 214);
  }

  &Text {
    color: rgba(94, 94, 94, 0.8);
    text-align: center;
    font-size: 16px;
  }
}

.inputLabel {
  font-weight: 600;
  font-size: 25px;
  line-height: 31px;
  color: #000000;
}

.input {
  background: #f1f1f1;
  border-radius: 30px;
}

.logo {
  width: 150px;
  margin-bottom: 12px;
}

.loginButton {
  font-weight: 900;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
  background: linear-gradient(99.98deg, #215d7f 18.73%, #047c93 81.12%);
  border-radius: 30px;
  padding: 0px 30px;
  border: none;
  width: fit-content;
}

.google-imgBox {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.footer {
  margin-top: 30px;
}

.footer p {
  color: rgba(94, 94, 94, 0.8);
  text-align: center;
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.footer a {
  font-size: 18px;
  color: #047c93;
  font-weight: 600;
  text-decoration: none;
}

.resetText {
  color: #008095;
  margin: 0.5rem 0 2rem;
}

.showPassword {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: rgb(71, 155, 210);
  cursor: pointer;
}

.inputCode {
  margin-bottom: 6px;
}

.resendOtp {
  color: #008095;
  cursor: pointer;
  width: fit-content;

  &:hover {
    text-decoration: underline;
  }
}

.inputCodeWrapper {
  margin: 3rem 0 2rem;
}

.inputWrapper {
  position: relative;
  margin: 0.5rem 0 1rem;
}

.changePwdBtn {
  margin-top: 2rem;
  background: linear-gradient(99.98deg, #215D7F 18.73%, #047C93 81.12%);
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  color: #FFFFFF;
  padding: 8px 40px;
  cursor: pointer;
  width: fit-content;
}

.tokenExpired {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
}

.tokenExpiredLoginButton {
  float: right;
  margin-top: 10px;
}

.googleSignin {
  display: inline-block;
  width: 200px;
  height: auto;
  cursor: pointer;
}

.signinRightText-mobile {
  display: none;
}

@media only screen and (max-width: 700px) {
  .singinBg {
    display: none;
  }

  .leftContainer {
    width: 50%;
    background-image: url("../../assets/img/signinbg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    padding: 50px;
    justify-content: center;
  }

  .btnBox {
    margin-bottom: 0;
  }

  .btnBox button {
    border: 1px solid #ffffff;
    padding: 2px 32px;
    font-size: 18px;
  }

  .inputBox input {
    padding: 5px 11px;
    font-size: 14px;
  }

  .inputBox label {
    padding-bottom: 10px;
  }

  .inputBox label,
  .leftHeading,
  .footer p,
  .footer a {
    color: #ffffff;
    margin-left: 0;
  }

  .leftHeading {
    display: none;
  }

  .signinRightText-mobile {
    display: block;
    color: white;
    font-size: 20px;

    h1 {
      font-size: 20px;
    }
  }

  .efficientHiring {
    font-size: 20px;
    padding: 2px 10px;
  }

  .logo {
    width: 200px;
    margin-bottom: 35px;
    background: white;
    border-radius: 10px;
    padding: 10px;
  }
}