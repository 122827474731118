.chrome__btn {
    border: 1px solid #f52d2d;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    color: #e92525;
    padding: 8px 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: block;
    margin: 0 10px;
    position: relative;
}

.blue__btn {
    border: 1px solid #479bd2;
    background-color: #479bd2;
    border-radius: 25px;
    font-size: 13px;
    font-weight: 600;
    color: #fff;
    padding: 8px 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: inline-block;
    position: relative;
    text-decoration: none;

    &:hover {
        opacity: 0.8;
    }
}

@media screen and (max-width: 900px) {
    .chrome__btn {
        font-size: 6px;
        padding: 4px 14px;
        margin: 0;
    }
}