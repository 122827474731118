.container {
    width: 100%;
}

.container {
    color: #000 !important;
}

.input {
    box-shadow: none !important;
    /* max-height: 38px !important; */
    height: 30px !important;
}

.placeholder {
    font-size: 13px !important;
    color: #000 !important;
    opacity: 0.3;
}

.input > input {
    box-shadow: none !important;
    font-size: 14px !important;
    height: 28px !important;
    color: #000 !important;
}

.multiValue,
.option,
.singleValue {
    font-size: 14px !important;
}

.control {
    background-color: #f6f6f6 !important;
    border-color: transparent !important;
}

.valueContainer {
    background-color: #f6f6f6 !important;
    border-color: transparent !important;
    padding: 0px 8px !important;
}
