.sp {
    &__relevanceLoader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100vh - 323px);
    }

    &__container,
    &__container--authorised {
        margin-top: 10px;
        overflow-y: auto;
        padding-right: 10px;
        height: calc(100vh - 348px);

        &::-webkit-scrollbar {
            width: 5px;
            background: #E2E2E2;
            border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
            background: #8CC0E3;
            border-radius: 4px;
        }
    }

    &__container--authorised {
        height: calc(100vh - 382px);
    }


    @media screen and (max-width: 900px) {
        &__container {
            height: calc(100vh - 168px);
        }

        &__container--authorised {
            height: calc(100vh - 230px);
        }
    }
}