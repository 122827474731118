.container {
    height: 99vh;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 20px;
}

.buttonContainer {
    padding: 10px;
    min-height: 70px;
}

.cardContainer {
    padding: 10px;
    border: 1px solid #eee;
    margin-bottom: 15px;
    border-radius: 15px;
    width: 100%;
    box-shadow: 0px 2px 4px 0px #eee;;
    cursor: pointer;
}

.org {
    margin-left: 10px;
}

.profileImage {
    margin-right: 25px;
}

.addToProject {
    width: 200px;
    height: 35px;
}

.addToProjectContainer {
    min-width: 400px;
    padding: 30px;
}

.addToProjectTitle {
    margin-bottom: 10px !important;
}

.addToProjectSelect {
    padding: 10px !important;
    background-color: #eee !important;

}

.addToProjectCancel {
    margin-right: 10px;
    width: 150px;
}
.addToProjectAdd {
    width: 150px;
}


.candidateText {
    opacity: 0.6;
    margin-bottom: 15px;
}

.candidateSkills {
    padding: 5px 10px;
    margin: 4px !important;
    background-color: #efefef;
    width: fit-content;
    border-radius: 5px;
    display: inline-block;
}

.locationTitle {
    margin-right: 10px;
}

.linkedInLink {
    color: #479bd2 !important;
    float: right;
}