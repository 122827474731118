.text {
    display: inline;
    width: 100%;
}

.read-or-hide {
    color: rgb(192, 192, 192);
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .text {
        font-size: 10px;
    }
}