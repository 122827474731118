.mainContainer {
    flex: 1;
    padding: 10px 20px;
    width: calc(100% - 260px);
}

.app {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #f5f6f8;
    position: relative;
    height: 100%;
}

.mobileView {
    display: none;
}

@media only screen and (max-width: 900px) {
    .mainContainer {
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 700px) {
    .app {
        display: none;
    }

    .mobileView {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: center;
        text-align: center;
    }

    .logo {
        width: 190px;
    }

    .text {
        font-size: 13px;
    }
}
