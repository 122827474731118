.table {
  &__legend {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    flex-shrink: 0;
  }

  &__demo {
    background-color: #088397;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    width: fit-content;
    padding: 0 4px;
    border-radius: 2px;
    transform: translateY(-14px);
    margin-bottom: -10px;
  }

  &__name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__text {
    color: #404040;
    font-size: 15px;
    font-weight: 500;
  }

  & th {
    color: #334D6E !important;
    font-size: 18px;
    font-weight: 500 !important;
  }

  & td {
    cursor: pointer;
  }

  &__changeStatus {
    display: inline-flex;
    align-items: center;

    p {
      font-size: 14px;
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 900px) {
    & th,
    &__text {
      font-size: 9px;
    }
    &__demo {
      font-size: 7px;
    }
  }
}