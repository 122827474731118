.sp {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-right {
            display: flex;
            align-items: center;
            background-color: #EDEDED;
            padding: 6px;
            border-radius: 25px;
        }

        &Wrapper {
            display: inline-flex;
            align-items: center;
        }
    }

    &__heading {
        display: flex;
        align-items: center;

        >p {
            color: #334D6E;
            font-size: 20px;
            font-weight: 500;
        }
    }

    &__headingBtnBox {
        display: flex;
        align-items: center;
        margin: 0 10px;
    }

    &__viewTab {
        display: inline-flex;
        align-items: center;
        color: #AEAEAE;
        font-size: 14px;
        font-weight: 500;
        border-radius: 18px;
        transition: 0.3s all ease-in-out;
        padding: 4px 10px;
        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
            margin-right: 8px;
        }

        &-active {
            color: #479BD2;
            background-color: #fff;
        }
    }

    &__createDate {
        color: #90A0B7;
        font-size: 12px;
    }

    &__projectCreation {
        display: inline-flex;
        align-items: center;
        margin-left: 10px;

        p {
            background-color: #fff;
            font-size: 12px;
            font-weight: 500;
            font-style: italic;
            padding: 5px 10px;
            width: fit-content;
            border: 1.5px solid #088397;
            border-radius: 8px;
        }
    }

    @media screen and (max-width: 900px) {
        &__heading {
          display: flex;
          align-items: center;

          >p {
            font-size: 10px;
          }
        }
        &__createDate {
            font-size: 8px;
        }

        &__infoIconButton {
            padding: 0 !important;
        }
        &__infoIcon {
            font-size: 10px !important;
            width: 10px !important;
            height: 10px !important;
        }
        &__headerWrapper {
            display: unset;
        }

        &__actionbar {
            padding: 6px;
        }
    }
}