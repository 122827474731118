* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

html,
body,
#root {
    background: #ffffff;
    height: 100%;
}

input,
button {
    outline: none;
    border: none;
    font-family: "Poppins", sans-serif;
}

button:disabled {
    opacity: 0.7;
}

.bg-green {
    background: #4fca64;
}

.bg-red {
    background: #e92525;
}

.bg-gray {
    background: #8c8c8c;
}

.ql-editor {
    height: 300px !important;
}

.ant-modal-content {
    border-radius: 30px !important;
}

.ant-table-body {
    overflow-x: auto;
    margin: 5px;

    &::-webkit-scrollbar {
        width: 5px;
        background: #e2e2e2;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #8cc0e3;
        border-radius: 4px;
    }
}

.ant-table.ant-table-fixed-header {
    border-radius: 30px;
}

.ant-table-header {
    border-radius: 30px 30px 0 0 !important;
}

.ant-table-row:hover .ant-dropdown-trigger {
    visibility: visible;
}

.ant-table-row .ant-dropdown-trigger {
    visibility: hidden;
}

.ant-switch-checked {
    background-color: #088397 !important;
}

.ant-select-selector {
    font-size: 12px !important;
}

.ant-select-item {
    font-size: 12px !important;
}

.email-quill .ql-toolbar {
    display: none !important;
}

.email-quill .ql-container {
    border: 1px solid #ccc !important;
}

.follow-up-quill .ql-toolbar {
    display: none !important;
}

.follow-up-quill .ql-container {
    border: 1px solid #ccc !important;
}

.follow-up-quill .ql-editor {
    height: 150px !important;
    background-color: #f3f6f9 !important;
    color: rgba(0, 0, 0, 0.38) !important;
}

.email-quill .ql-editor {
    background-color: #f3f6f9 !important;
    color: rgba(0, 0, 0, 0.38) !important;
}
