.msg {
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__actionbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    padding: 15px;
    margin-top: 20px;
  }

  &__actionItem {
    color: #334D6E;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 8px;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    span {
      color: #90A0B7;
      font-weight: 400;
      margin-left: 5px;
      border-radius: 50%;
      font-size: 12px;
      background-color: #DFDFDF;
      margin-left: 10px;
      width: 24px;
      height: 24px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &--active {
      background-color: #F2F6FF;
      border-radius: 12px;

      span {
        background-color: #6599FF;
        color: #fff;
      }
    }
  }

  &__body {
    flex: 1;
    padding: 10px 0;
    border-radius: 30px 30px 0 0;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    max-height: calc(100vh - 189px);
  }

  &__left {
    width: 40%;
    margin-right: 15px;
  }

  &__right {
    flex: 1;
  }

  &__no-data {
    &-img {
      display: block;
      width: auto;
      height: 400px;
      margin: 0 auto;
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      letter-spacing: 0.01em;
      color: rgba(146, 146, 146, 0.7);
    }
  }

  @media screen and (max-width: 900px) {
    &__actionbar {
      padding: 3px;
    }

    &__actionItem {
      font-size: 10px;
      padding: 2px 8px;

      span {
        width: 18px;
        height: 18px;
        font-size: 10px;
      }
    }

    &__body {
      margin-top: 0px;
      max-height: calc(100vh - 80px);
    }

    &__no-data-img {
      height: 300px;
    }

    &__no-data-text {
      font-size: 10px;
    }
  }
}

.msgl {
  &__container {
    background: #fff;
    border-radius: 30px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1);
    height: 100%;
  }

  &__titlebox {
    border-bottom: 3px solid #EBEFF2;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    color: #404040;
    padding: 12px 20px;
  }

  &__body {
    max-height: calc(100vh - 266px);
    overflow-y: auto;
    padding: 0.5rem;
  }

  @media screen and (max-width: 900px) {
    &__title,
    &__name {
      font-size: 10px !important;
    }
  }
}