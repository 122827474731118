.sidebar {
    width: 260;
    background: #fff;
    box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;

    &--minimize {
        width: fit-content !important;
    }

    &__container {
        color: #555;
        display: flex;
        flex-direction: column;
    }

    &__logobox {
        border-bottom: 1px solid #ccc;
        display: flex;
        align-items: center;
    }

    &__logo {
        height: auto;
        width: 11vw;

        &-small {
            width: 28px;
            height: 28px;
            display: inline-block;
        }
    }

    &__userbox {
        display: flex;
        align-items: center;
        margin-top: 20px;
    }

    &__userimg {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 1rem;
    }

    &__avatar {
        padding: 20px;
        border-radius: 50%;
        background-color: #c4c4c4;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #000;
    }

    &__userdetails {
        margin-left: 0.6rem;
        font-size: 14px;
        font-weight: 500;
        color: #192a3e;
        word-break: break-all;
    }

    &__linkBox {
        margin-top: 20px;
        flex: 1;
    }

    &__itemBox {
        margin: 6px 8px 16px;
    }

    &__item,
    &__workflow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #334d6e;

        &-single {
            font-size: 14px;
        }

        &-icon {
            width: 20px !important;
            height: 20px !important;
            color: #334d6e;
        }

        &-img {
            width: 20px;
            height: 20px;
            margin-right: 20px;
        }

        &--active {
            color: #109cf1;
        }

        a {
            color: inherit;
            text-decoration: none;
        }
    }

    &__workflow {
        opacity: 0.5;
        cursor: no-drop;
    }

    &__ul {
        margin: 10px 0 0 40px;
        list-style: none;
    }

    &__li {
        display: block;
        text-decoration: none;
        font-size: 13px;
        color: #c4c4c4;
        padding: 5px 0;

        &--active {
            color: #109cf1;
        }
    }

    &__cta {
        cursor: pointer;
    }

    &__btnBox {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #334d6e;
        margin-left: 8px;

        display: flex;
        align-items: center;
    }

    &__helpBox {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 15px;
        padding-bottom: 10px;
        text-align: center;
    }

    &__copyBox {
        border-top: 1px solid #ebeff2;
        font-size: 11px;
        color: #334d6e;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        img {
            margin-left: 15px;
            display: block;
            width: 80px;
            height: 27px;
        }
    }

    &__footer {
        margin-top: auto;
    }

    @media screen and (max-width: 900px) {
        width: 150px;

        &__userdetails {
            font-size: 10px;
        }

        &__item,
        &__workflow {
            &-single {
                font-size: 8px;
            }

            &-icon {
                width: 10px !important;
                height: 10px !important;
            }
        }

        &__itemBox {
            margin: 6px 8px 8px;
        }

        &__howToUse {
            font-size: 7px !important;
        }
    }
}