.referralModal {
    padding: 0;
    font-family: "";
}

.referralModal .ant-modal-content {
    padding: 0;
    background-color: transparent !important;
}

.rightContainer {
    padding: 50px;
}

.referralCodeContainer {
    background-image: url("../../assets/img/code-bg.png");
    width: 90%;
    height: 176px;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    align-items: center;
}


.referralCodeLogo {
    padding-right: 1rem;
    border-right: 2px dashed rgba(102, 102, 102, 0.2);
}

.referralFormContainer {
    display: flex;
    flex-direction: column;
}

.referralFormInputsContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
    gap: 0.5rem;
}

.referText {
    font-size: 30px !important;
}

.referralCode {
    text-align: center;
}

.referralCodeText {
    font-weight: 500;
    font-size: 15px !important;
}

@media screen and (max-width: 900px) {
    .rightContainer {
        padding: 20px;
    }

    .referText,
    .referralCode {
        font-size: 20px !important;
    }
    .referralCodeText {
        font-size: 15px !important;
    }

    .heroImg {
        width: 200px !important;
    }

    .findRightText,
    .referTextLabel {
        font-size: 10px !important;
    }

    .hQlogo {
        width: 50px !important;
    }

    .sendReferralButton {
        font-size: 10px;
    }

}